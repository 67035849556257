@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .shadow-text {
    text-shadow: 0px 0px 1px #000;
  }
  .shadow-text-lg {
    text-shadow: 2px 2px 2px #000;
  }
  .form-input {
    @apply bg-gray-200 appearance-none border-2 border-gray-200 rounded-3xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500;
  }
  .label-form {
    @apply text-sm font-bold text-white shadow-text;
  }
  .alert-form {
    @apply bg-red-200 rounded text-xs font-light my-1 text-red-900 p-1;
  }
  .checkbox-form {
    @apply text-gray-800 font-bold bg-gray-200 flex items-center p-3 rounded-3xl text-center justify-center;
  }
  #g-recaptcha {
    @apply bg-transparent text-center mx-auto;
  }
}